<template>
  <div class="Permission-group">
    <div class="Card">
      <div class="Card__header has-background">
        <h3 class="is-size-5">
          {{ ucfirst(name) }}
        </h3>
      </div>
      <div class="Card__body">
        <permission
          v-for="permission in permissions"
          :key="permission.id"
          :source="permission"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ucfirstMixin from '@hypefactors/shared/js/mixins/UcfirstMixin'

import Permission from './Permission'

export default {
  components: {
    Permission
  },

  mixins: [ucfirstMixin],

  props: {
    name: {
      type: String,
      default: ''
    },

    permissions: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.Permission-group {
}
</style>
