<template>
  <div class="Permission is-flex is-aligned-middle is-aligned-justify" @click="isAllowed = !isAllowed">
    <div>
      <h4>{{ source.name }}</h4>
      <small>This is a description of the role.</small>
    </div>

    <!-- TODO: Remove disable when perm overrides are allowed -->
    <el-checkbox v-model="isAllowed" disabled />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters(['authorize']),

    isAllowed: {
      get () {
        return this.authorizeBool(this.source.slug)
      },

      set (val) {
        console.log('NOT IMPLEMENTED')
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.Permission {
  cursor: pointer;
  padding: $padding-small $padding;
  margin-left: -$margin;
  margin-right: -$margin;
  @include transit(background-color);

  &:hover {
    background-color: rgba($grey-lighter, 0.25);
  }

  small {
    color: $grey;
  }
}
</style>
