<template>
  <div class="Permissions-management">
    <Heading size="4">
      {{ $t('pages.settings.user.permissions_management') }}
    </Heading>

    <div class="is-flex is-aligned-middle is-full-width m-b-l">
      <RoundAvatar :src="undefined" :acronym-fallback="user.name" class="m-r-m" />

      <div class="is-flex is-aligned-middle">
        <span class="is-bold">{{ user.name }}</span>:

        <BasicSelect
          :values="roles"
          :value="user.role.data.id || 1"
          class="m-h-s"
          label-prop="name"
          value-prop="id"
          key-prop="id"
          @change="assignRole"
        />
        in {{ activeBrand.name }}
      </div>
    </div>

    <div class="columns is-multiline">
      <PermissionGroup
        v-for="(group, name) in groupedPermissions"
        :key="name"
        :name="name"
        :permissions="group"
        class="column is-6"
      />
    </div>
  </div>
</template>

<script>
import { groupBy } from 'lodash'
import { mapGetters } from 'vuex'

import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'
import Heading from '@hypefactors/shared/js/components/core/Heading.vue'
import RoundAvatar from '@hypefactors/shared/js/components/core/RoundAvatar.vue'

import PermissionGroup from '@/components/roles/PermissionGroup.vue'

export default {
  components: {
    BasicSelect,
    Heading,
    RoundAvatar,
    PermissionGroup
  },

  data: () => ({
    user: {
      name: 'John Doe',
      role: {
        data: {
          id: 3
        }
      }
    },
    roles: [],
    permissions: []
  }),

  computed: {
    ...mapGetters(['activeBrand']),

    groupedPermissions () {
      return groupBy(this.permissions, 'group')
    }
  },

  mounted () {
    this.fetchRoles()
    this.fetchPermissions()
    this.fetchUser()
  },

  methods: {
    async fetchRoles () {
      try {
        this.roles = (await this.$api.get('roles')).data.data
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    async fetchPermissions () {
      try {
        this.permissions = (await this.$api.get('permissions')).data.data
      } catch (err) {
        this.$displayRequestError(err)
      }
    },

    async fetchUser () {
      // TODO: implement
      // try {
      //   this.user = (await this.$api.get('users/' + this.$route.params.user_id)).data.data
      // } catch (err) {
      //   this.$displayRequestError(err)
      // }
    },

    assignRole (roleId) {
      console.log(roleId)
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/styles/vueStyleUtils.scss';

.Permissions-management {
 .el-select {
   width: 200px;
 }
}
</style>
